import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';
import { IWixAPI, IAppData } from '@wix/native-components-infra/dist/src/types/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';

import { TabState, CheckoutData, PopupEnum } from '../../types/common';
import { encodeBase64Url } from '../../services/encode-base64';
import { plansFixture } from '../../fixtures';
import { ListProps } from '../PackagePicker/Widget/List';
import { PlansApi } from '../../services/plans';
import { PlanListInteractions } from '../../types/PlanListFedops';
import { PricingPlansBi } from '../../services/bi';
import { Analytics } from '../../services/analytics';
import { SettingsReader } from '../PackagePicker/DefaultSettingsAdapter';

export class PlanListController {
  constructor(
    public setProps: (props: Partial<ListProps>) => void,
    protected plansApi: PlansApi,
    protected flowAPI: ControllerFlowAPI,
    protected wixCodeApi: IWixAPI,
    protected appParams: IAppData,
    protected bi: PricingPlansBi,
    protected analytics: Analytics,
    protected settings: SettingsReader,
  ) {}

  public async initialize() {
    this.flowAPI.fedopsLogger.interactionStarted(PlanListInteractions.WidgetLoaded);

    const plans: PublicPlan[] = await this.fetchPlans();
    const useFixture = !plans?.length && this.wixCodeApi.window.viewMode === 'Editor';

    this.setProps({
      plans: useFixture ? plansFixture : plans,
      tabState: TabState.REGULAR,
      selectPlan: this.navigateToCheckout,
      hidePopup: this.hidePopup,
      popup: null,
    });

    this.bi.plansPageView(undefined, 'list');
    this.analytics.addProductImpression(plans);
  }

  fetchPlans = async () => {
    if (this.settings.get('usePagination')) {
      const include = this.settings.asArray('visiblePlans');
      return this.plansApi.query(include.length > 0 ? { include } : { exclude: this.settings.asArray('hiddenPlans') });
    } else {
      return this.plansApi.loadPaidPlans({ planIds: [] });
    }
  };

  private navigateToCheckout = async (plan: PublicPlan) => {
    this.flowAPI.fedopsLogger.interactionStarted(PlanListInteractions.NavigateToList);

    if (this.wixCodeApi.window.viewMode !== 'Site') {
      this.setProps({ popup: PopupEnum.checkoutPreview });
      this.flowAPI.fedopsLogger.interactionEnded(PlanListInteractions.NavigateToList);
      return;
    }

    this.bi.planPurchaseClick(plan.id ?? '', 'list');
    this.analytics.clickProduct(plan);

    const checkoutData: CheckoutData = { planId: plan.id!, integrationData: {} };
    this.setCurrentPath('/payment/' + encodeBase64Url(checkoutData));
  };

  private hidePopup = () => this.setProps({ popup: null });

  private setCurrentPath = async (path: string) => {
    const { relativeUrl } = await this.wixCodeApi.site.getSectionUrl({
      appDefinitionId: this.appParams.appDefinitionId,
      sectionId: 'membership_plan_picker_tpa',
    });
    this.wixCodeApi.location.to!((relativeUrl ?? '') + path);
    this.flowAPI.fedopsLogger.interactionEnded(PlanListInteractions.NavigateToList);
  };
}
